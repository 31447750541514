<template>
    <div>
        <div @click='display = !display' style='width:90%;height:40px;background-color:lightgrey;border:3px solid grey;border-radius:20px;text-align:center' class='mx-auto mt-1 pt-1'>Indiquer un changement sur cet appareil</div>
        <div v-if='display' style='border:3px solid grey;' class='ma-5 mt-1 pa-2'>
            <div class='pa-2 mb-5' style='background-color:lightgrey'>Pour faire une demande de modification de caractéristiques de l'appareil veuillez prendre une photo de la plaque et laissez en commentaire les changements.</div>
            <v-toolbar-title class='global-main-title' >Demande de modification</v-toolbar-title>
            <cx-upload-photo :id='appareil_id' model='appareils' type='modification' class='mt-2' @upload='upload'></cx-upload-photo>
            <v-textarea
                background-color="grey lighten-2"
                label="Commentaire"
                v-model='commentaire'
                class=' mt-1'
            ></v-textarea> 
            <cx-button label='Soumettre la modification' :disabled='required' @click='submit' :loading="loading"></cx-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CxUpdateAppareils", 
        components : {
            'CxButton': () => import('../../components/action/CxButton'),
            'CxUploadPhoto': () => import('../../components/action/CxUploadPhoto')
        },
        props : {
            appareil_id: {
                type: Number,
                default: 0
            },
            intervention_id: {
                type: Number,
                default: 0
            }
        },
        computed: {
            required() { return !this.uploaded || this.commentaire == ''}
        },
        data () {
            return {
                display: false,
                commentaire : '',
                uploaded : false,
                loading : false,
            }
        },
        methods: {
            upload() {
                this.uploaded = true
            },
            submit() {

                let data = {
                    appareil_id : this.appareil_id,
                    obs_modification : this.commentaire
                }

                this.loading = true;

                this.$services.request.post('api/pwa/mes-interventions/intervention/demande-modification',data).then(()=>{
                    
                    this.$services.powerLib.notification.showSnackbar({
                                message : 'La demande a bien été effectuée',
                                type : 'success'
                    })
                    this.loading = false;
                    location.reload()

                }).catch(()=> {
                    this.loading = false;
                    this.$services.powerLib.notification.showSnackbar({
                                message : 'Une erreur est survenue lors de la demande',
                                type : 'error'
                    })
                })
            }
        }
    }
</script>